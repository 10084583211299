import { AxiosResponse } from "axios";
import axiosInstance from "./config/axios";
import { getCurrentDate } from "../infrastructure/helperMethods";
import { TreatmentsReportInput } from "./models/input/TreatmentsReportInput";
class ReportsApi {
  private controllerRoute = "/api/reports";

  public getTreatmentReports(input: TreatmentsReportInput) {
    return axiosInstance({
      method: "post",
      url: `${this.controllerRoute}/treatments-report`,
      data: input,
      responseType: "blob",
    }).then(this.handleDownload);
  }

  private handleDownload = (response: AxiosResponse) => {
    const href = URL.createObjectURL(response.data);
    // create "a" HTML element with href to file & click
    const link = document.createElement("a");
    link.href = href;
    link.setAttribute("download", `Raport-${getCurrentDate("-")}.xls`);
    link.click();
    window.URL.revokeObjectURL(href);
    // clean up "a" element & remove ObjectURL
    URL.revokeObjectURL(href);
  };
}
export default new ReportsApi();
