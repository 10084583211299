import { IconButton, Tooltip } from "@mui/material";
import {
  DataGrid,
  GridColDef,
  GridToolbar,
  GridValueFormatterParams,
} from "@mui/x-data-grid";
import React, { useEffect, useState } from "react";
import AccountApi from "../../api/AccountApi";
import { buttonClasses, tableClasses } from "../styles";
import ArticleIcon from "@mui/icons-material/Article";
import { useNavigate } from "react-router-dom";
import { ProtectedRoutes } from "../../infrastructure/Routes";
import { useSnackbar } from "notistack";
import { getRoleName, Role } from "../../types/Role";
import { Account } from "../../api/models/output/GetAccountsOutput";
interface AccountsTableProps {}

const AccountsTable: React.FC<AccountsTableProps> = () => {
  const [accounts, setAccounts] = useState<Account[]>([]);
  const [pageSize, setPageSize] = React.useState<number>(10);
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    getAccounts();
  }, []);

  const getColumnsDefinition = () => {
    const columnsDefinition: GridColDef[] = [
      {
        field: "displayName",
        headerName: "Nume",
        flex: 1,
        headerClassName: "header-cell",
      },
      {
        field: "role",
        headerName: "Funcție",
        flex: 1,
        headerClassName: "header-cell",
        valueFormatter: (params: GridValueFormatterParams) => {
          return getRoleName(params.value as Role);
        },
      },
      {
        field: "id",
        headerName: "Acțiuni",
        flex: 0.5,
        headerClassName: "header-cell",
        sortable: false,
        filterable: false,
        renderCell: (params) => {
          return (
            <div>
              <Tooltip title="Vezi detalii">
                <IconButton
                  sx={buttonClasses.gridActionButton}
                  onClick={() => {
                    navigate(ProtectedRoutes.account, {
                      state: { accountId: params.row.id },
                    });
                  }}
                >
                  <ArticleIcon />
                </IconButton>
              </Tooltip>
            </div>
          );
        },
      },
    ];
    return columnsDefinition;
  };

  const getAccounts = () => {
    AccountApi.getAccounts()
      .then((response) => {
        setAccounts(response.data.accounts);
      })
      .catch((err) => {
        enqueueSnackbar("Nu s-au putut obține conturile", {
          variant: "error",
        });
      });
  };

  return (
    <DataGrid
      sx={tableClasses.table}
      components={{
        Toolbar: GridToolbar,
      }}
      disableSelectionOnClick
      autoHeight
      pageSize={pageSize}
      onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
      rowsPerPageOptions={[10, 25, 50]}
      columns={getColumnsDefinition()}
      rows={accounts}
    ></DataGrid>
  );
};

export default AccountsTable;
