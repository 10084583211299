import React, { createContext, useState, useContext, useEffect } from "react";
import AccountApi from "../api/AccountApi";
import { AuthenticationOutput } from "../api/models/output/AuthenticationOutput";

interface UserProviderProps {
  children: React.ReactNode;
}

type UserContextType = {
  user: AuthenticationOutput | null;
  setUser: Function;
};

const UserContext = createContext<UserContextType>({} as UserContextType);

const UserProvider: React.FC<UserProviderProps> = (
  props: UserProviderProps
) => {
  const [user, setUser] = useState(null);
  useEffect(() => {
    AccountApi.restoreSession()
      .then((response) => setUser(response.data))
      .catch((err) => {});
  }, []);

  return (
    <UserContext.Provider value={{ user, setUser }}>
      {props.children}
    </UserContext.Provider>
  );
};

const useUserContext = () => useContext(UserContext);

export { UserProvider, useUserContext };
