import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import DateTimePicker from "./DateTimePicker";
import { buttonClasses, dialogClasses, textClasses } from "./styles";

interface DateDialogProps {
  open: boolean;
  title: string;
  onSave: (value: Date) => void;
  onClose: () => void;
  message: string;
}

const DateDialog: React.FC<DateDialogProps> = (props: DateDialogProps) => {
  const [date, setDate] = useState<Date | null>(new Date());
  const [isError, setIsError] = useState(false);

  useEffect(() => {
    setIsError(false);
  }, [props.open]);

  return (
    <Dialog open={props.open} fullWidth maxWidth={"sm"}>
      <DialogTitle sx={dialogClasses.title}>{props.title}</DialogTitle>
      <DialogContent>
        <DialogContentText sx={textClasses.normal}>
          {props.message}
        </DialogContentText>
        <Grid
          container
          direction={"column"}
          justifyContent="center"
          alignItems="center"
          spacing={2}
          sx={dialogClasses.content}
        >
          {isError ? (
            <Grid item>
              <Typography sx={textClasses.error}>
                Dată mai mică decât cea curentă
              </Typography>
            </Grid>
          ) : null}
          <Grid item>
            <DateTimePicker
              onChange={(event) => {
                setDate(new Date(event.target.value));
              }}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button sx={buttonClasses.secondary} onClick={props.onClose}>
          Închide
        </Button>
        <Button
          sx={buttonClasses.primary}
          onClick={() => {
            const today = new Date();
            if (today < date!) {
              props.onSave(date!);
            } else {
              setIsError(true);
            }
          }}
        >
          Confirmă
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DateDialog;
