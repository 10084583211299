import { IconButton, Tooltip } from "@mui/material";
import { DataGrid, GridColDef, GridToolbar } from "@mui/x-data-grid";
import React, { useEffect, useState } from "react";
import AccountApi from "../../api/AccountApi";
import { Account } from "../../api/models/output/GetTechniciansOutput";
import { buttonClasses, tableClasses } from "../styles";
import VisibilityIcon from "@mui/icons-material/Visibility";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { useNavigate } from "react-router-dom";
import { ProtectedRoutes } from "../../infrastructure/Routes";
import { useSnackbar } from "notistack";
interface TechniciansTableProps {
  onSelectTechnician: (technician: Account) => void;
}

const TechniciansTable: React.FC<TechniciansTableProps> = ({
  onSelectTechnician,
}) => {
  const [technicians, setTechnicians] = useState<Account[]>([]);
  const [pageSize, setPageSize] = React.useState<number>(5);
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    getTechnicians();
  }, []);

  const getColumnsDefinition = () => {
    const columnsDefinition: GridColDef[] = [
      {
        field: "displayName",
        headerName: "Nume",
        flex: 1,
        headerClassName: "header-cell",
      },
      {
        field: "ongoingTreatments",
        headerName: "Număr Tratamente",
        flex: 1,
        headerClassName: "header-cell",
      },
      {
        field: "id",
        headerName: "Acțiuni",
        flex: 0.5,
        headerClassName: "header-cell",
        sortable: false,
        filterable: false,
        renderCell: (params) => {
          return (
            <div>
              <Tooltip title="Vezi tratamente">
                <IconButton
                  sx={buttonClasses.gridActionButton}
                  onClick={() => {
                    onSelectTechnician(params.row);
                  }}
                >
                  <VisibilityIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title="Adaugă tratament">
                <IconButton
                  sx={buttonClasses.gridActionButton}
                  onClick={() => {
                    navigate(ProtectedRoutes.treatment, {
                      state: { isCreate: true, technician: params.row },
                    });
                  }}
                >
                  <AddCircleOutlineIcon />
                </IconButton>
              </Tooltip>
            </div>
          );
        },
      },
    ];
    return columnsDefinition;
  };

  const getTechnicians = () => {
    AccountApi.getTechnicians()
      .then((response) => {
        setTechnicians(response.data.technicians);
      })
      .catch((err) => {
        enqueueSnackbar("Nu s-au putut obține tehnicienii", {
          variant: "error",
        });
      });
  };

  return (
    <DataGrid
      sx={tableClasses.table}
      components={{
        Toolbar: GridToolbar,
      }}
      disableSelectionOnClick
      autoHeight
      pageSize={pageSize}
      onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
      rowsPerPageOptions={[5, 10, 20]}
      columns={getColumnsDefinition()}
      rows={technicians}
    ></DataGrid>
  );
};

export default TechniciansTable;
