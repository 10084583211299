import { Grid, IconButton, TextField, Typography } from "@mui/material";
import React, { useState } from "react";
import { Treatment } from "../../api/models/output/GetTreatmentOutput";
import { buttonClasses, textClasses } from "../styles";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import EditIcon from "@mui/icons-material/Edit";
import CheckIcon from "@mui/icons-material/Check";
import RepeatIcon from "@mui/icons-material/Repeat";
import DateRangeIcon from "@mui/icons-material/DateRange";
import { getDateAndTime, parseDate } from "../../infrastructure/helperMethods";
import { useUserContext } from "../../infrastructure/UserContext";
import { Role } from "../../types/Role";
import DateDialog from "../DateDialog";
import TreatmentApi from "../../api/TreatmentApi";
import { useSnackbar } from "notistack";
import { Status } from "../../types/Status";
import ConfirmationDialog from "../ConfirmationDialog";
import { useNavigate } from "react-router-dom";
import { ProtectedRoutes } from "../../infrastructure/Routes";
import { getPatientTypeName, PatientType } from "../../types/PatientType";
interface TreatmentDetailsProps {
  treatment?: Treatment;
  refreshData: () => void;
}

type DialogsState = {
  changeDialog: boolean;
  repeatStep: boolean;
  confirmStep: boolean;
  finishStep: boolean;
  deleteTreatment: boolean;
};

const TreatmentDetails: React.FC<TreatmentDetailsProps> = ({
  treatment,
  refreshData,
}) => {
  const { user } = useUserContext();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const [dialogsState, setDialogsState] = useState<DialogsState>({
    changeDialog: false,
    repeatStep: false,
    finishStep: false,
    confirmStep: false,
    deleteTreatment: false,
  });
  const isLast = treatment?.isLastStep;

  const changeDeadeline = (deadline: Date): void => {
    setDialogsState({ ...dialogsState, changeDialog: false });
    TreatmentApi.changeDeadline(treatment!.id, deadline)
      .then((response) => {
        enqueueSnackbar("Termenul limită a fost modificat", {
          variant: "success",
        });
        refreshData();
      })
      .catch((err) => {
        enqueueSnackbar("Nu s-a putut modifica termenul limită", {
          variant: "error",
        });
      });
  };

  const repeatStep = (deadline: Date): void => {
    setDialogsState({ ...dialogsState, repeatStep: false });
    TreatmentApi.repeatStep(treatment!.id, deadline)
      .then((response) => {
        enqueueSnackbar("Etapa se repetă", {
          variant: "success",
        });
        refreshData();
      })
      .catch((err) => {
        enqueueSnackbar("Nu s-a putut modifica termenul limită", {
          variant: "error",
        });
      });
  };

  const finishStep = (): void => {
    setDialogsState({ ...dialogsState, finishStep: false });
    TreatmentApi.finishStep(treatment!.id)
      .then((response) => {
        enqueueSnackbar("Etapa a fost finalizată", {
          variant: "success",
        });
        navigate(-1);
      })
      .catch((err) => {
        enqueueSnackbar("Nu s-a putut finaliza etapa", {
          variant: "error",
        });
      });
  };

  const confirmStep = (deadline?: Date): void => {
    setDialogsState({ ...dialogsState, confirmStep: false });
    TreatmentApi.confirmStep(
      treatment!.id,
      deadline === undefined ? new Date() : deadline
    )
      .then((response) => {
        enqueueSnackbar(
          isLast ? "Tratamentul a fost finalizat" : "Etapa a fost confirmată",
          {
            variant: "success",
          }
        );
        if (!isLast) {
          refreshData();
        } else {
          navigate(-1);
        }
      })
      .catch((err) => {
        enqueueSnackbar(
          isLast
            ? "Nu s-a putut finaliza termenul"
            : "Nu s-a putut confirma etapa",
          {
            variant: "error",
          }
        );
      });
  };

  const deleteTreatment = (): void => {
    setDialogsState({ ...dialogsState, deleteTreatment: false });
    TreatmentApi.deleteTreatment(treatment!.id)
      .then((response) => {
        enqueueSnackbar("Tratamentul a fost șters", {
          variant: "success",
        });
        navigate(-1);
      })
      .catch((err) => {
        enqueueSnackbar("Nu s-a putut șterge tratamentul", {
          variant: "error",
        });
      });
  };

  const isUsersTreatment = () => {
    if (user?.role === Role.Admin) return true;
    if (treatment?.medic.id === user?.id) return true;
    if (treatment?.technician.id === user?.id) return true;
    return false;
  };

  const isMedic = () => {
    return (
      user?.role === Role.Admin ||
      user?.role === Role.MedicAdmin ||
      user?.role === Role.Medic
    );
  };

  const isTechnician = () => {
    return user?.role === Role.Admin || user?.role === Role.Technician;
  };

  return (
    <Grid
      item
      container
      direction={"column"}
      spacing={3}
      justifyContent={"center"}
      alignItems={"center"}
      xs={12}
      sx={{ minWidth: "750px" }}
    >
      {isUsersTreatment() && isMedic() ? (
        <Grid item container direction={"row"} spacing={3}>
          <Grid item xs={4}>
            <IconButton
              sx={buttonClasses.actionButton}
              onClick={() => {
                navigate(ProtectedRoutes.treatment, {
                  state: { isEdit: true, treatmentId: treatment?.id },
                });
              }}
            >
              <EditIcon />
              Editează tratament
            </IconButton>
          </Grid>
          <Grid item xs={4}>
            <IconButton
              sx={buttonClasses.actionButton}
              onClick={() => {
                setDialogsState({ ...dialogsState, deleteTreatment: true });
              }}
            >
              <DeleteForeverIcon />
              Șterge tratament
            </IconButton>
          </Grid>
        </Grid>
      ) : null}

      <Grid
        item
        container
        direction={"row"}
        justifyContent={"center"}
        sx={textClasses.normal}
      >
        <Grid item container direction="column" xs={6}>
          <Grid item>
            <Typography component="span" sx={textClasses.label}>
              Număr identificare (ID):
            </Typography>
            {treatment?.id}
          </Grid>
          <Grid item>
            <Typography component="span" sx={textClasses.label}>
              Tratament:
            </Typography>
            {treatment?.name}
          </Grid>
          {treatment?.step ? (
            <Grid item>
              <Typography component="span" sx={textClasses.label}>
                Etapă:
              </Typography>
              {treatment?.step}
            </Grid>
          ) : null}
          <Grid item>
            <Typography component="span" sx={textClasses.label}>
              Status:
            </Typography>
            {treatment?.stepStatus === Status.Finished
              ? "Așteaptă confirmare"
              : "În lucru"}
          </Grid>
          <Grid item>
            <Typography component="span" sx={textClasses.label}>
              Termen limită:
            </Typography>
            {getDateAndTime(parseDate(treatment?.stepDeadline))}
          </Grid>
          <br />

          <Grid item>
            <Typography component="span" sx={textClasses.label}>
              Medic:
            </Typography>
            {treatment?.medic.displayName}
          </Grid>
          <Grid item>
            <Typography component="span" sx={textClasses.label}>
              Tehnician:
            </Typography>
            {treatment?.technician.displayName}
          </Grid>
          <Grid item>
            <Typography component="span" sx={textClasses.label}>
              Pacient:
            </Typography>
            {treatment?.patientName}
          </Grid>
          <Grid item>
            <Typography component="span" sx={textClasses.label}>
              Tip Pacient:
            </Typography>
            {treatment?.patientType !== undefined
              ? getPatientTypeName(treatment!.patientType)
              : null}
          </Grid>
        </Grid>
        <Grid item container direction="column" xs={6}>
          <Grid item>
            <Typography sx={textClasses.label}>Descriere:</Typography>
            <TextField
              fullWidth
              sx={textClasses.area}
              inputProps={{ readOnly: Boolean(true) }}
              maxRows={6}
              multiline
              value={treatment?.description}
            />
          </Grid>
          <Grid item>
            <Typography sx={textClasses.label}>Istoric:</Typography>
            <TextField
              fullWidth
              sx={textClasses.area}
              inputProps={{ readOnly: Boolean(true) }}
              maxRows={6}
              multiline
              value={treatment?.log}
            />
          </Grid>
        </Grid>
      </Grid>
      {isUsersTreatment() ? (
        <Grid item container direction={"row"} spacing={3}>
          {isMedic() ? (
            <>
              {treatment?.stepStatus === Status.InProgress ? (
                <>
                  <Grid item>
                    <IconButton
                      sx={buttonClasses.actionButton}
                      onClick={() => {
                        setDialogsState({
                          ...dialogsState,
                          changeDialog: true,
                        });
                      }}
                    >
                      <DateRangeIcon />
                      Modifică termenul limită
                    </IconButton>
                  </Grid>
                </>
              ) : (
                <Grid item>
                  <IconButton
                    sx={buttonClasses.actionButton}
                    onClick={() => {
                      setDialogsState({ ...dialogsState, repeatStep: true });
                    }}
                  >
                    <RepeatIcon />
                    Repetă etapă
                  </IconButton>
                </Grid>
              )}
              <Grid item>
                <IconButton
                  sx={buttonClasses.actionButton}
                  onClick={() => {
                    setDialogsState({ ...dialogsState, confirmStep: true });
                  }}
                >
                  <CheckIcon />
                  {isLast ? "Finalizează tratament" : "Confirmă etapă"}
                </IconButton>
              </Grid>
            </>
          ) : null}
          {isTechnician() && treatment?.stepStatus === Status.InProgress ? (
            <Grid item>
              <IconButton
                sx={buttonClasses.actionButton}
                onClick={() => {
                  setDialogsState({ ...dialogsState, finishStep: true });
                }}
              >
                <CheckIcon />
                Finalizează etapă
              </IconButton>
            </Grid>
          ) : null}
        </Grid>
      ) : null}

      <DateDialog
        title="Schimbă termenul limită"
        open={dialogsState.changeDialog}
        onSave={changeDeadeline}
        onClose={() =>
          setDialogsState({ ...dialogsState, changeDialog: false })
        }
        message="Selectează un termen limită nou"
      ></DateDialog>
      <DateDialog
        title="Repetă etapa"
        open={dialogsState.repeatStep}
        onSave={repeatStep}
        onClose={() => setDialogsState({ ...dialogsState, repeatStep: false })}
        message="Selectează un termen limită"
      ></DateDialog>
      <ConfirmationDialog
        title="Finalizează etapa"
        open={dialogsState.finishStep}
        onSave={finishStep}
        onClose={() => setDialogsState({ ...dialogsState, finishStep: false })}
        message="Dorești să marchezi etapa curentă ca finalizată?"
      ></ConfirmationDialog>

      {isLast ? (
        <ConfirmationDialog
          title="Finalizează tratament"
          open={dialogsState.confirmStep}
          onSave={confirmStep}
          onClose={() =>
            setDialogsState({ ...dialogsState, confirmStep: false })
          }
          message="Dorești să marchezi tratamentul ca finalizat?"
        ></ConfirmationDialog>
      ) : (
        <DateDialog
          title="Confirmă etapa"
          open={dialogsState.confirmStep}
          onSave={confirmStep}
          onClose={() =>
            setDialogsState({ ...dialogsState, confirmStep: false })
          }
          message="Selectează un termen limită pentru următoarea etapă"
        ></DateDialog>
      )}
      <ConfirmationDialog
        title="Ștergere tratament"
        open={dialogsState.deleteTreatment}
        onSave={deleteTreatment}
        onClose={() =>
          setDialogsState({ ...dialogsState, deleteTreatment: false })
        }
        message="Dorești să ștergi tratamentul?"
      ></ConfirmationDialog>
    </Grid>
  );
};

export default TreatmentDetails;
