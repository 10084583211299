import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { buttonClasses, dialogClasses, textClasses } from "./styles";

interface ConfirmationDialogProps {
  open: boolean;
  title: string;
  onSave: () => void;
  onClose: () => void;
  message: string;
}

const ConfirmationDialog: React.FC<ConfirmationDialogProps> = (
  props: ConfirmationDialogProps
) => {
  return (
    <Dialog open={props.open} fullWidth maxWidth={"xs"}>
      <DialogTitle sx={dialogClasses.title}>{props.title}</DialogTitle>
      <DialogContent>
        <DialogContentText sx={textClasses.normal}>
          {props.message}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button sx={buttonClasses.secondary} onClick={props.onClose}>
          Închide
        </Button>
        <Button
          sx={buttonClasses.primary}
          onClick={() => {
            props.onSave();
          }}
        >
          Confirmă
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmationDialog;
