import React, { useEffect, useState } from "react";
import {
  DataGrid,
  GridColDef,
  GridToolbar,
  GridValueFormatterParams,
} from "@mui/x-data-grid";
import { Status } from "../../types/Status";
import TreatmentApi from "../../api/TreatmentApi";
import { Role } from "../../types/Role";
import { useUserContext } from "../../infrastructure/UserContext";
import { getDateAndTime, parseDate } from "../../infrastructure/helperMethods";
import { buttonClasses, tableClasses } from "../styles";
import { IconButton, Tooltip } from "@mui/material";
import ArticleIcon from "@mui/icons-material/Article";
import { useNavigate } from "react-router-dom";
import { ProtectedRoutes } from "../../infrastructure/Routes";
import { useSnackbar } from "notistack";
import { Treatment } from "../../api/models/output/GetUserTreatmentOutput";
interface TreatmentsTableProps {
  userId?: number;
}
type TreatmentGridItem = {
  id: number;
  name: string;
  step: string | null;
  patientName: string;
  medicName: string;
  technicianName: string;
  deadline: Date;
  stepStaus: Status;
};

const TreatmentsTable: React.FC<TreatmentsTableProps> = ({ userId }) => {
  const { user } = useUserContext();
  const [treatments, setTreatments] = useState<Treatment[]>([]);
  const [pageSize, setPageSize] = React.useState<number>(10);
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    getTreatments();
  }, [userId]);

  const getColumnsDefinition = () => {
    const columnsDefinition: GridColDef[] = [
      {
        field: "id",
        headerName: "ID",
        flex: 0.15,
        headerClassName: "header-cell",
      },
      {
        field: "name",
        headerName: "Tip tratament",
        flex: 0.9,
        headerClassName: "header-cell",
      },
      {
        field: "step",
        headerName: "Etapă",
        flex: 0.9,

        headerClassName: "header-cell",
      },
      {
        field: "medicName",
        headerName: "Medic",
        flex: 0.45,
        headerClassName: "header-cell",
      },
      {
        field: "technicianName",
        headerName: "Tehnician",
        flex: 0.45,
        headerClassName: "header-cell",
      },
      {
        field: "patientName",
        headerName: "Nume pacient",
        flex: 0.45,
        headerClassName: "header-cell",
      },
      {
        field: "deadline",
        headerName: "Termen limită",
        flex: 0.3,
        headerClassName: "header-cell",
        valueFormatter: (params: GridValueFormatterParams) => {
          return getDateAndTime(parseDate(params.value as string));
        },
        sortComparator: (v1, v2) => {
          const date1 = parseDate(v1 as string);
          const date2 = parseDate(v2 as string);
          if (date1 > date2) return 1;
          if (date1 < date2) return -1;
          return 0;
        },
      },
      {
        field: "stepStaus",
        headerName: "Status",
        flex: 0.3,
        headerClassName: "header-cell",

        valueFormatter: (params: GridValueFormatterParams) => {
          return (params.value as Status) === Status.InProgress
            ? "În lucru"
            : "Așteaptă confirmare";
        },
      },
      {
        field: "actions",
        headerName: "Acțiuni",
        flex: 0.25,
        headerClassName: "header-cell",
        sortable: false,
        filterable: false,
        renderCell: (params) => {
          return (
            <Tooltip title="Detalii tratament">
              <IconButton
                sx={buttonClasses.gridActionButton}
                onClick={() => {
                  navigate(ProtectedRoutes.treatment, {
                    state: { treatmentId: params.row.id },
                  });
                }}
              >
                <ArticleIcon />
              </IconButton>
            </Tooltip>
          );
        },
      },
    ];
    if (user?.role === Role.Admin) return columnsDefinition;
    if (userId || user?.role === Role.Technician)
      return columnsDefinition.filter((x) => x.field !== "technicianName");
    if (user?.role === Role.Medic || user?.role === Role.MedicAdmin)
      return columnsDefinition.filter((x) => x.field !== "medicName");

    return columnsDefinition;
  };

  const getRows = (): TreatmentGridItem[] => {
    return treatments.map(
      (x): TreatmentGridItem => ({
        id: x.id,
        name: x.name,
        medicName: x.medic.displayName,
        technicianName: x.technician.displayName,
        patientName: x.patientName,
        step: x.step,
        deadline: x.stepDeadline,
        stepStaus: x.stepStatus,
      })
    );
  };

  const getTreatments = () => {
    TreatmentApi.getUserTreatments(userId === undefined ? user!.id : userId)
      .then((response) => {
        setTreatments(response.data.treatments);
      })
      .catch((err) => {
        enqueueSnackbar("Nu s-au putut obține tratamentele", {
          variant: "error",
        });
      });
  };

  return (
    <DataGrid
      sx={tableClasses.table}
      components={{
        Toolbar: GridToolbar,
      }}
      disableSelectionOnClick
      autoHeight
      pageSize={pageSize}
      onPageSizeChange={(newPageSize: any) => setPageSize(newPageSize)}
      rowsPerPageOptions={[10, 15, 20]}
      columns={getColumnsDefinition()}
      rows={getRows()}
    ></DataGrid>
  );
};

export default TreatmentsTable;
