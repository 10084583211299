export enum ProtectedRoutes {
  yourActivity = "/",
  technicians = "/technicians",
  accounts = "/accounts",
  treatment = "/treatment",
  account = "/account",
  finishedTreatment = "/finished-treatment",
  reports = "/reports",
}

export enum NonProtectedRoutes {
  login = "/login",
  forbidden = "/forbidden",
}
