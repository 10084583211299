import {
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import TreatmentApi from "../../api/TreatmentApi";
import {
  getDateAndTime,
  isEmpty,
  parseDate,
} from "../../infrastructure/helperMethods";
import { Account } from "../../api/models/output/GetTechniciansOutput";
import { AddEditTreatmentInput } from "../../api/models/input/AddEditTreatmentInput";
import { BaseTreatment } from "../../types/BaseTreatment";
import DateDialog from "../DateDialog";
import { buttonClasses, textClasses } from "../styles";
import { Treatment } from "../../api/models/output/GetTreatmentOutput";
import { PatientType, PatientTypeNameValues } from "../../types/PatientType";
interface TreatmentFormProps {
  treatment?: Treatment;
  technician?: Account;
}

const TreatmentForm: React.FC<TreatmentFormProps> = ({
  treatment,
  technician,
}) => {
  const isCreate = treatment === undefined && technician !== undefined;
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const getInitialState = (): AddEditTreatmentInput => {
    if (isCreate) {
      return {
        technicianId: technician!.id,
        baseTreatmentId: null,
        patientName: "",
        description: "",
        stepDeadline: null,
        numberOfElements: null,
        patientType: PatientType.CityHallProgram,
      };
    }
    return {
      technicianId: treatment!.technician.id,
      baseTreatmentId: treatment!.baseTreatmentId,
      patientName: treatment!.patientName,
      description: treatment!.description,
      stepDeadline: null,
      numberOfElements: null,
      patientType: treatment!.patientType,
    };
  };

  const [treatmentInput, setTreatmentInput] = useState<AddEditTreatmentInput>(
    getInitialState()
  );
  const [baseTreatments, setBaseTreatments] = useState<BaseTreatment[]>();
  const [dateDialog, setDateDialog] = useState<boolean>(false);
  const [selectedBaseTreatment, setSelectedBaseTreatment] = useState<
    BaseTreatment | undefined
  >(undefined);

  useEffect(() => {
    if (isCreate) {
      TreatmentApi.getBaseTreatments()
        .then((response) => {
          setBaseTreatments(response.data);
        })
        .catch((err) => {});
    }
  }, [isCreate]);

  const handleInput = (e: React.FocusEvent<HTMLInputElement>) => {
    e.persist();
    setTreatmentInput({
      ...treatmentInput,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (date?: Date) => {
    setDateDialog(false);

    if (isCreate) {
      TreatmentApi.createTreatment({ ...treatmentInput, stepDeadline: date! })
        .then((response) => {
          enqueueSnackbar("Tratamentul a fost adăugat cu succes", {
            variant: "success",
          });
          navigate(-1);
        })
        .catch((err) => {
          enqueueSnackbar("Tratamentul nu s-a putut adăuga", {
            variant: "error",
          });
        });
    } else {
      TreatmentApi.editTreatment(treatmentInput, treatment!.id)
        .then((response) => {
          enqueueSnackbar("Tratamentul a fost editat cu succes", {
            variant: "success",
          });
          navigate(-1);
        })
        .catch((err) => {
          enqueueSnackbar("Tratamentul nu s-a putut edita", {
            variant: "error",
          });
        });
    }
  };

  const getModalMessage = (): string => {
    const baseTreatmnet = baseTreatments?.find(
      (t) => t.id === treatmentInput.baseTreatmentId
    );
    const isStep = baseTreatmnet?.steps[0] !== undefined;
    return isStep
      ? `Selectează termenul limită pentru pasul ${baseTreatmnet?.steps[0]}`
      : `Selectează termenul limită pentru tratamentul ${baseTreatmnet?.name}`;
  };

  return (
    <Grid
      item
      container
      direction={"column"}
      spacing={3}
      justifyContent={"center"}
      alignItems={"stretch"}
      sx={{ minWidth: "600px" }}
    >
      <Grid item sx={textClasses.normal}>
        <h2>{isCreate ? "Adăugare tratament" : "Editare tratament"}</h2>
        <p>
          Completează câmpurile de mai jos pentru a
          {isCreate ? " adăuga un tratament" : " edita tratamentul"}
        </p>
      </Grid>
      <Grid item sx={textClasses.normal}>
        <form
          onSubmit={(e: React.FormEvent<HTMLFormElement>) => {
            e.preventDefault();
            if (isCreate) {
              setDateDialog(true);
              return;
            }
            handleSubmit();
          }}
        >
          <Grid container direction="column" spacing={3}>
            <Grid item>
              {isCreate ? (
                <FormControl fullWidth required>
                  <InputLabel id="base-teratment">Tratament</InputLabel>
                  <Select
                    labelId="base-treatment"
                    id="demo-simple-select"
                    value={
                      treatmentInput.baseTreatmentId === null
                        ? ""
                        : treatmentInput.baseTreatmentId.toString()
                    }
                    onChange={(event: any) => {
                      var selectedBaseTreatment = baseTreatments?.find(
                        (x) => x.id === event.target.value
                      );
                      setTreatmentInput({
                        ...treatmentInput,
                        baseTreatmentId: event.target.value,
                        numberOfElements:
                          selectedBaseTreatment!.minNumberOfElements,
                      });
                      setSelectedBaseTreatment(
                        baseTreatments?.find((x) => x.id === event.target.value)
                      );
                    }}
                    label="Tratament"
                  >
                    {baseTreatments?.map((t) => (
                      <MenuItem key={t.id} value={t.id}>
                        {t.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              ) : (
                <TextField
                  fullWidth
                  disabled
                  value={treatment?.name}
                  label="Tratament"
                ></TextField>
              )}
            </Grid>
            {!isCreate ? (
              <>
                <Grid item>
                  {`Termenul limită pentru ${
                    isEmpty(treatment?.step) ? "tratamentul " : "pasul "
                  }`}
                  <Typography component="span" sx={textClasses.label}>
                    {isEmpty(treatment?.step)
                      ? treatment?.name
                      : treatment?.step}
                  </Typography>
                </Grid>
                <Grid item>
                  {` este în data de `}
                  <Typography component="span" sx={textClasses.label}>
                    {getDateAndTime(parseDate(treatment?.stepDeadline))}
                  </Typography>
                </Grid>
              </>
            ) : null}
            {isCreate &&
            selectedBaseTreatment &&
            selectedBaseTreatment!.maxNumberOfElements !== 0 ? (
              <>
                <Grid item>
                  <TextField
                    id="numberOfElements"
                    name="numberOfElements"
                    type="number"
                    placeholder="Număr de elemente"
                    required
                    label="Număr de elemente"
                    onChange={handleInput}
                    value={treatmentInput.numberOfElements}
                    fullWidth
                    InputProps={{
                      inputProps: {
                        min: selectedBaseTreatment.minNumberOfElements,
                        max:
                          selectedBaseTreatment.maxNumberOfElements === -1
                            ? 9999
                            : selectedBaseTreatment.maxNumberOfElements,
                      },
                    }}
                  />
                </Grid>
              </>
            ) : null}
            <Grid item>
              <TextField
                id="patientName"
                name="patientName"
                type="text"
                placeholder="Nume pacient"
                required
                label="Nume pacient"
                onChange={handleInput}
                value={treatmentInput.patientName}
                fullWidth
              />
            </Grid>
            <Grid item>
              <FormControl fullWidth required>
                <InputLabel id="patientType">Tip Pacinet</InputLabel>
                <Select
                  labelId="patientType"
                  value={treatmentInput.patientType.toString()}
                  onChange={(event: any) => {
                    setTreatmentInput({
                      ...treatmentInput,
                      patientType: event.target.value,
                    });
                  }}
                  label="Tip Pacient"
                >
                  {PatientTypeNameValues.map((t) => (
                    <MenuItem key={t.patyentTypeName} value={t.value}>
                      {t.patyentTypeName}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item>
              <TextField
                id="description"
                name="description"
                placeholder="Descriere"
                label="Descriere"
                onChange={handleInput}
                value={treatmentInput.description}
                fullWidth
                multiline
                rows={6}
              />
            </Grid>

            <Grid item container direction="row" spacing={1}>
              <Grid item xs={6}>
                <Button
                  sx={buttonClasses.secondary}
                  onClick={() => {
                    navigate(-1);
                  }}
                >
                  Anulează
                </Button>
              </Grid>
              <Grid item xs={6}>
                <Button sx={buttonClasses.primary} type="submit">
                  {isCreate ? "Adaugă" : "Salvează"}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </form>
        <DateDialog
          title="Selectează termenul limită "
          open={dateDialog}
          onSave={handleSubmit}
          onClose={() => setDateDialog(false)}
          message={getModalMessage()}
        ></DateDialog>
      </Grid>
    </Grid>
  );
};

export default TreatmentForm;
