import { TabPanel } from "@mui/lab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import { Grid, Tab, Typography } from "@mui/material";
import React, { useState } from "react";
import FinishedTreatmentsTable from "../../components/finishedTreatments/FinishedTreatmentsTable";
import { acordionClasses } from "../../components/styles";
import TreatmentsTable from "../../components/treatments/TreatmentsTable";
import AppNavBar from "../../infrastructure/AppNavBar";

interface YourActivityPageProps {}

const YourActivityPage: React.FC<YourActivityPageProps> = () => {
  const [value, setValue] = useState("1");

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  return (
    <div>
      <AppNavBar />
      <Grid
        container
        justifyContent={"flex-start"}
        alignItems={"stretch"}
        direction={"column"}
        sx={{ padding: "10px" }}
      >
        <Grid item>
          <TabContext value={value}>
            <TabList onChange={handleChange}>
              <Tab
                label={
                  <Typography sx={acordionClasses.summary}>
                    Tratamente în lucru
                  </Typography>
                }
                value="1"
              />
              <Tab
                label={
                  <Typography sx={acordionClasses.summary}>
                    Tratamente finalizate
                  </Typography>
                }
                value="2"
              />
            </TabList>

            <TabPanel value="1">
              <TreatmentsTable></TreatmentsTable>
            </TabPanel>
            <TabPanel value="2">
              <FinishedTreatmentsTable></FinishedTreatmentsTable>
            </TabPanel>
          </TabContext>
        </Grid>
      </Grid>
    </div>
  );
};

export default YourActivityPage;
