import AppRouter from "./infrastructure/AppRouter";
import { UserProvider } from "./infrastructure/UserContext";
import React from "react";
import { SnackbarProvider } from "notistack";

function App() {
  return (
    <SnackbarProvider maxSnack={3}>
      <UserProvider>
        <AppRouter />
      </UserProvider>
    </SnackbarProvider>
  );
}

export default App;
