import { Grid, IconButton, Typography } from "@mui/material";
import { useSnackbar } from "notistack";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import AccountApi from "../../api/AccountApi";
import { Account } from "../../api/models/output/GetAccountOutput";
import ConfirmationDialog from "../ConfirmationDialog";
import { buttonClasses, textClasses } from "../styles";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import PasswordIcon from "@mui/icons-material/Password";
import { getRoleName } from "../../types/Role";

interface AccountDetailsProps {
  account?: Account;
  refreshData: () => void;
}
type DialogsState = {
  deleteAccount: boolean;
  regeneratePassword: boolean;
};
const AccountDetails: React.FC<AccountDetailsProps> = ({
  account,
  refreshData,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const [dialogsState, setDialogsState] = useState<DialogsState>({
    deleteAccount: false,
    regeneratePassword: false,
  });

  const deleteAccount = (): void => {
    setDialogsState({ ...dialogsState, deleteAccount: false });
    AccountApi.deleteAccount(account!.id)
      .then((response) => {
        enqueueSnackbar("Contul a fost șters", {
          variant: "success",
        });
        navigate(-1);
      })
      .catch((err) => {
        enqueueSnackbar("Nu s-a putut șterge contul", {
          variant: "error",
        });
        if (err?.response?.status === 400) {
          enqueueSnackbar("Utilizatorul are tratamente active", {
            variant: "error",
          });
        }
      });
  };

  const regeneratePassword = (): void => {
    setDialogsState({ ...dialogsState, regeneratePassword: false });
    AccountApi.regeneratePassword(account!.id)
      .then((response) => {
        enqueueSnackbar("Parola contului a fost regenerată", {
          variant: "success",
        });
        navigate(-1);
      })
      .catch((err) => {
        enqueueSnackbar("Nu s-a putut regenera parola", {
          variant: "error",
        });
      });
  };

  return (
    <Grid
      item
      container
      direction={"column"}
      spacing={3}
      justifyContent={"center"}
      alignItems={"center"}
      xs={12}
      sx={{ minWidth: "750px" }}
    >
      <Grid item container direction={"row"} spacing={3}>
        {/* <Grid item xs={4}>
            <IconButton
              sx={buttonClasses.actionButton}
              onClick={() => {
                navigate(ProtectedRoutes.treatment, {
                  state: { isEdit: true, treatmentId: treatment?.id },
                });
              }}
            >
              <EditIcon />
              Editează tratament
            </IconButton>
          </Grid> */}
        <Grid item xs={4}>
          <IconButton
            sx={buttonClasses.actionButton}
            onClick={() => {
              setDialogsState({ ...dialogsState, deleteAccount: true });
            }}
          >
            <DeleteForeverIcon />
            Șterge cont
          </IconButton>
        </Grid>
        <Grid item xs={4}>
          <IconButton
            sx={buttonClasses.actionButton}
            onClick={() => {
              setDialogsState({ ...dialogsState, regeneratePassword: true });
            }}
          >
            <PasswordIcon />
            Regenerare parolă
          </IconButton>
        </Grid>
      </Grid>

      <Grid
        item
        container
        direction={"column"}
        justifyContent={"center"}
        sx={textClasses.normal}
      >
        <Grid item>
          <Typography component="span" sx={textClasses.label}>
            Nume:
          </Typography>
          {account?.displayName}
        </Grid>

        <Grid item>
          <Typography component="span" sx={textClasses.label}>
            Nume utilizator:
          </Typography>
          {account?.username}
        </Grid>

        <Grid item>
          <Typography component="span" sx={textClasses.label}>
            Email:
          </Typography>
          {account?.email}
        </Grid>
        <Grid item>
          <Typography component="span" sx={textClasses.label}>
            Telefon:
          </Typography>
          {account?.phoneNumber}
        </Grid>
        <Grid item>
          <Typography component="span" sx={textClasses.label}>
            Rol:
          </Typography>
          {account?.role ? getRoleName(account!.role) : null}
        </Grid>
      </Grid>

      <ConfirmationDialog
        title="Ștergere cont"
        open={dialogsState.deleteAccount}
        onSave={deleteAccount}
        onClose={() =>
          setDialogsState({ ...dialogsState, deleteAccount: false })
        }
        message="Dorești să ștergi contul?"
      ></ConfirmationDialog>

      <ConfirmationDialog
        title="Regenerare parolă"
        open={dialogsState.regeneratePassword}
        onSave={regeneratePassword}
        onClose={() =>
          setDialogsState({ ...dialogsState, regeneratePassword: false })
        }
        message="Dorești să regenerezi parola contului?"
      ></ConfirmationDialog>
    </Grid>
  );
};

export default AccountDetails;
