export enum PatientType {
  CityHallProgram = 0,
  InternshipProgram = 1,
  OtherSituations = 2,
}

export const PatientTypeNameValues = [
  { patyentTypeName: "Program Primărie", value: 0 },
  { patyentTypeName: "Stagiu", value: 1 },
  { patyentTypeName: "Alte situații", value: 2 },
];

export const getPatientTypeName = (patyentType: PatientType): string => {
  switch (patyentType) {
    case PatientType.CityHallProgram:
      return "Program Primărie";
    case PatientType.InternshipProgram:
      return "Stagiu";
    case PatientType.OtherSituations:
      return "Alte situații";
    default:
      throw "Invalid PatyentType";
  }
};
