import axiosInstance from "./config/axios";
class FinishedTreatmentApi {
  private controllerRoute = "/api/finished-treatments";

  public getUserFinishedTreatments(userId: number) {
    return axiosInstance.get(this.controllerRoute + `?userId=${userId}`);
  }

  public getFinishedTreatment(treatmentId: number) {
    return axiosInstance.get(this.controllerRoute + `/${treatmentId}`);
  }
}
export default new FinishedTreatmentApi();
