import React, { useEffect, useState } from "react";
import {
  DataGrid,
  GridColDef,
  GridToolbar,
  GridValueFormatterParams,
} from "@mui/x-data-grid";
import { Role } from "../../types/Role";
import { useUserContext } from "../../infrastructure/UserContext";
import { getDateAndTime, parseDate } from "../../infrastructure/helperMethods";
import { buttonClasses, tableClasses } from "../styles";
import { IconButton, Tooltip } from "@mui/material";
import ArticleIcon from "@mui/icons-material/Article";
import { useNavigate } from "react-router-dom";
import { ProtectedRoutes } from "../../infrastructure/Routes";
import { useSnackbar } from "notistack";
import { FinishedTreatment } from "../../api/models/output/GetUserFinishedTreatmentsOutput";
import { getPatientTypeName, PatientType } from "../../types/PatientType";
import FinishedTreatmentApi from "../../api/FinishedTreatmentApi";

interface FinishedTreatmentsTableProps {
  userId?: number;
}

const FinishedTreatmentsTable: React.FC<FinishedTreatmentsTableProps> = ({
  userId,
}) => {
  const { user } = useUserContext();
  const [finishedTreatments, setFinishedTreatments] = useState<
    FinishedTreatment[]
  >([]);
  const [pageSize, setPageSize] = React.useState<number>(10);
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    getTreatments();
  }, [userId]);

  const getColumnsDefinition = () => {
    const columnsDefinition: GridColDef[] = [
      {
        field: "treatmentId",
        headerName: "ID",
        flex: 0.15,
        headerClassName: "header-cell",
      },
      {
        field: "treatmentName",
        headerName: "Tip tratament",
        flex: 0.9,
        headerClassName: "header-cell",
      },

      {
        field: "medic",
        headerName: "Medic",
        flex: 0.45,
        headerClassName: "header-cell",
      },
      {
        field: "technician",
        headerName: "Tehnician",
        flex: 0.45,
        headerClassName: "header-cell",
      },
      {
        field: "patientName",
        headerName: "Nume pacient",
        flex: 0.45,
        headerClassName: "header-cell",
      },
      {
        field: "startDate",
        headerName: "Dată adăugare",
        flex: 0.3,
        headerClassName: "header-cell",
        valueFormatter: (params: GridValueFormatterParams) => {
          return getDateAndTime(parseDate(params.value as string));
        },
        sortComparator: (v1, v2) => {
          const date1 = parseDate(v1 as string);
          const date2 = parseDate(v2 as string);
          if (date1 > date2) return 1;
          if (date1 < date2) return -1;
          return 0;
        },
      },
      {
        field: "endDate",
        headerName: "Dată finalizare",
        flex: 0.3,
        headerClassName: "header-cell",
        valueFormatter: (params: GridValueFormatterParams) => {
          return getDateAndTime(parseDate(params.value as string));
        },
        sortComparator: (v1, v2) => {
          const date1 = parseDate(v1 as string);
          const date2 = parseDate(v2 as string);
          if (date1 > date2) return 1;
          if (date1 < date2) return -1;
          return 0;
        },
      },
      {
        field: "patientType",
        headerName: "Tip pacient",
        flex: 0.3,
        headerClassName: "header-cell",

        valueFormatter: (params: GridValueFormatterParams) => {
          return getPatientTypeName(params.value as PatientType);
        },
      },
      {
        field: "actions",
        headerName: "Acțiuni",
        flex: 0.25,
        headerClassName: "header-cell",
        sortable: false,
        filterable: false,
        renderCell: (params) => {
          return (
            <Tooltip title="Detalii tratament">
              <IconButton
                sx={buttonClasses.gridActionButton}
                onClick={() => {
                  debugger;
                  navigate(ProtectedRoutes.finishedTreatment, {
                    state: { treatmentId: params.row.treatmentId },
                  });
                }}
              >
                <ArticleIcon />
              </IconButton>
            </Tooltip>
          );
        },
      },
    ];
    if (user?.role === Role.Admin) return columnsDefinition;
    if (userId || user?.role === Role.Technician)
      return columnsDefinition.filter((x) => x.field !== "technician");
    if (user?.role === Role.Medic || user?.role === Role.MedicAdmin)
      return columnsDefinition.filter((x) => x.field !== "medic");

    return columnsDefinition;
  };

  const getTreatments = () => {
    FinishedTreatmentApi.getUserFinishedTreatments(
      userId === undefined ? user!.id : userId
    )
      .then((response) => {
        setFinishedTreatments(response.data.data.finishedTreatments);
      })
      .catch((err) => {
        enqueueSnackbar("Nu s-au putut obține tratamentele", {
          variant: "error",
        });
      });
  };

  return (
    <DataGrid
      getRowId={(row: any) => row.treatmentId}
      sx={tableClasses.table}
      components={{
        Toolbar: GridToolbar,
      }}
      disableSelectionOnClick
      autoHeight
      pageSize={pageSize}
      onPageSizeChange={(newPageSize: any) => setPageSize(newPageSize)}
      rowsPerPageOptions={[10, 15, 20]}
      columns={getColumnsDefinition()}
      rows={finishedTreatments}
    ></DataGrid>
  );
};

export default FinishedTreatmentsTable;
