import { Button, Grid } from "@mui/material";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import AccountApi from "../../api/AccountApi";
import { Account } from "../../api/models/output/GetAccountOutput";
import AccountDetails from "../../components/accounts/AccountDetails";
import AccountForm from "../../components/accounts/AccountForm";
import { buttonClasses } from "../../components/styles";
import AppNavBar from "../../infrastructure/AppNavBar";

interface AccountPageProps {}
type AccountRenderParams = {
  isCreate?: boolean;
  isEdit?: boolean;
  accountId?: number;
};
const AccountPage: React.FC<AccountPageProps> = () => {
  const { state } = useLocation();
  const navigate = useNavigate();
  const renderParams = state as AccountRenderParams;
  const [account, setAccount] = useState<Account | undefined>(undefined);
  const { enqueueSnackbar } = useSnackbar();
  useEffect(() => {
    getAccount();
  }, [renderParams]);

  const getAccount = () => {
    if (renderParams.accountId !== undefined) {
      AccountApi.getAccount(renderParams.accountId)
        .then((response) => {
          setAccount(response.data.account);
        })
        .catch((err) => {
          enqueueSnackbar("Nu s-au putut obține date despre cont", {
            variant: "error",
          });
        });
    }
  };

  return (
    <div>
      <AppNavBar />
      <Grid
        container
        direction={"column"}
        justifyContent={"center"}
        alignItems={"center"}
        spacing={3}
        sx={{ padding: "10px" }}
      >
        <Grid item>
          {renderParams.isCreate ? (
            <AccountForm />
          ) : renderParams.isEdit ? null : (
            <AccountDetails account={account} refreshData={getAccount} />
          )}
        </Grid>
        {!(renderParams.isCreate || renderParams.isEdit) ? (
          <Grid item>
            <Button
              sx={buttonClasses.secondary}
              onClick={() => {
                navigate(-1);
              }}
            >
              Înapoi la pagina anterioară
            </Button>
          </Grid>
        ) : null}
      </Grid>
    </div>
  );
};

export default AccountPage;
