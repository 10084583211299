import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import TechniciansTable from "../../components/accounts/TechniciansTable";
import AppNavBar from "../../infrastructure/AppNavBar";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import TreatmentsTable from "../../components/treatments/TreatmentsTable";
import { acordionClasses } from "../../components/styles";
import { Account } from "../../api/models/output/GetTechniciansOutput";

interface TechniciansPageProps {}

const TechniciansPage: React.FC<TechniciansPageProps> = () => {
  const [technician, setTechnician] = useState<Account | null>(null);

  const selectTechnician = (technician: Account) => {
    setTechnician(technician);
  };

  return (
    <div>
      <AppNavBar />
      <Grid
        container
        justifyContent={"flex-start"}
        alignItems={"stretch"}
        direction={"column"}
        sx={{ padding: "10px" }}
      >
        <Grid item>
          <Accordion defaultExpanded={true}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography sx={acordionClasses.summary}>
                Tabel Tehnicieni
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <TechniciansTable
                onSelectTechnician={selectTechnician}
              ></TechniciansTable>
            </AccordionDetails>
          </Accordion>
          {technician !== null ? (
            <Accordion defaultExpanded={true}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography sx={acordionClasses.summary}>
                  Tratamentele tehnicianului {technician.displayName}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <TreatmentsTable userId={technician?.id}></TreatmentsTable>
              </AccordionDetails>
            </Accordion>
          ) : null}
        </Grid>
      </Grid>
    </div>
  );
};

export default TechniciansPage;
