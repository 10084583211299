import { AuthenticationInput } from "./models/input/AuthenticationInput";
import { AddAccountInput } from "./models/input/AddAccountInput";
import axiosInstance from "./config/axios";

class AccountApi {
  private controllerRoute = "/api/accounts";

  public authenticate(credentials: AuthenticationInput) {
    return axiosInstance.post(
      this.controllerRoute + "/authenticate",
      credentials
    );
  }

  public logout() {
    return axiosInstance.get(this.controllerRoute + "/logout");
  }

  public restoreSession() {
    return axiosInstance.get(this.controllerRoute + "/restore-session");
  }

  public getAccounts() {
    return axiosInstance.get(this.controllerRoute);
  }

  public getAccount(id: number) {
    return axiosInstance.get(this.controllerRoute + `/${id}`);
  }

  public getTechnicians() {
    return axiosInstance.get(this.controllerRoute + "/technicians");
  }

  public createAccount(account: AddAccountInput) {
    return axiosInstance.post(this.controllerRoute, account);
  }

  public deleteAccount(id: number) {
    return axiosInstance.delete(this.controllerRoute + `/${id}`);
  }

  public regeneratePassword(id: number) {
    return axiosInstance.post(this.controllerRoute + "/regenerate-password", {
      accountId: id,
    });
  }
}

export default new AccountApi();
