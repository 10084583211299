import { color, text } from "../../styleConstants";

export const classes = {
  root: {
    height: "100vh" as const,
    with: "100vw" as const,
    boxSizing: "border-box" as const,
  },
  dent: {
    color: color.primary,
    fontWeight: text.weight.extraBold,
    fontSize: text.size.title,
  },
  track: {
    color: color.secondary,
    fontWeight: text.weight.bold,
    fontSize: text.size.title,
  },

  typography: {
    textAlign: "center" as const,
    fontSize: text.size.medium,
    fontWeight: text.weight.medium,
    color: color.text.normal,
  },
  errorMessage: {
    fontSize: text.size.normal,
    fontWeight: text.weight.medium,
    textAlign: "center" as const,
    alignItems: "center" as const,
    color: color.text.error,
  },
};
