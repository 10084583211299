import { Button, Grid, TextField, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import AccountApi from "../../api/AccountApi";
import { buttonClasses } from "../../components/styles";
import { ProtectedRoutes } from "../../infrastructure/Routes";
import { useUserContext } from "../../infrastructure/UserContext";
import { classes } from "./styles";

interface LoginPageProps {}

const LoginPage: React.FC<LoginPageProps> = (props) => {
  const [credentials, setCredentials] = useState({
    username: "",
    password: "",
  });
  const [isError, setIsError] = useState(false);
  let { user, setUser } = useUserContext();
  const navigate = useNavigate();

  useEffect(() => {
    if (user !== null)
      navigate(ProtectedRoutes.yourActivity, { replace: true });
  }, [user, navigate]);

  const handleInput = (e: React.FocusEvent<HTMLInputElement>) => {
    e.persist();
    setCredentials({
      ...credentials,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    AccountApi.authenticate(credentials)
      .then((response) => {
        setUser(response.data);
        navigate(ProtectedRoutes.yourActivity, { replace: true });
      })
      .catch((err) => {
        setIsError(true);
      });
  };

  return (
    <Grid
      container
      direction="column"
      justifyContent="center"
      alignItems="center"
      sx={classes.root}
      spacing={3}
    >
      <Grid item>
        <Typography sx={classes.dent} display="inline">
          Dent
        </Typography>
        <Typography display="inline" sx={classes.track}>
          Track
        </Typography>
      </Grid>
      <Grid item>
        <Typography sx={classes.typography}>
          Pentru a vă autentifica folosiți credențialele primite
        </Typography>
      </Grid>
      <Grid item>
        <form onSubmit={handleSubmit}>
          <Grid container direction="column" spacing={3}>
            {isError ? (
              <Grid item>
                <Typography sx={classes.errorMessage}>
                  Nume de utilizator sau parola incorecte
                </Typography>
              </Grid>
            ) : null}
            <Grid item>
              <TextField
                id="username"
                name="username"
                type="text"
                placeholder="Nume utilizator"
                required
                label="Nume utilizator"
                onChange={handleInput}
                fullWidth
              />
            </Grid>
            <Grid item>
              <TextField
                id="password"
                name="password"
                type="password"
                placeholder="Parolă"
                required
                label="Parolă"
                onChange={handleInput}
                fullWidth
              />
            </Grid>
            <Grid item>
              <Button type="submit" sx={buttonClasses.primary}>
                Autentificare
              </Button>
            </Grid>
          </Grid>
        </form>
      </Grid>
    </Grid>
  );
};

export default LoginPage;
