export enum Role {
  Technician = 1,
  Medic = 2,
  MedicAdmin = 3,
  Admin = 4,
}

export const RoleNameValues = [
  { roleName: "Tehnician", value: 1 },
  { roleName: "Medic", value: 2 },
  { roleName: "Medic Administrator", value: 3 },
];

export const getRoleName = (role: Role): string => {
  switch (role) {
    case Role.Admin:
      return "Administrator";
    case Role.MedicAdmin:
      return "Medic Administrator";
    case Role.Medic:
      return "Medic";
    case Role.Technician:
      return "Tehnician";
    default:
      throw "Invalid role";
  }
};
