import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import MenuIcon from "@mui/icons-material/Menu";
import { buttonClasses } from "../components/styles";
import { color } from "../styleConstants";
import { useUserContext } from "./UserContext";
import { Role } from "../types/Role";
import AccountApi from "../api/AccountApi";
import { useNavigate } from "react-router-dom";
import { ProtectedRoutes } from "./Routes";

const AppNavBar = () => {
  const [anchorElNav, setAnchorElNav] = React.useState<any>(null);
  const { user, setUser } = useUserContext();
  const navigate = useNavigate();
  const pages =
    user?.role === Role.Technician
      ? ["Tratamente", "Rapoarte"]
      : user?.role === Role.Medic
      ? ["Tratamente", "Tehnicieni", "Rapoarte"]
      : ["Tratamente", "Tehnicieni", "Conturi", "Rapoarte"];

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = (page: string) => {
    setAnchorElNav(null);
    switch (page) {
      case "Tratamente":
        navigate(ProtectedRoutes.yourActivity, { replace: true });
        break;
      case "Tehnicieni":
        navigate(ProtectedRoutes.technicians, { replace: true });
        break;
      case "Conturi":
        navigate(ProtectedRoutes.accounts, { replace: true });
        break;
      case "Rapoarte":
        navigate(ProtectedRoutes.reports, { replace: true });
        break;
    }
  };

  const handleLogout = () => {
    AccountApi.logout()
      .then((response) => {
        setUser(null);
      })
      .catch((err) => {});
  };

  return (
    <AppBar position="static" sx={{ backgroundColor: color.secondary }}>
      <Container maxWidth={false}>
        <Toolbar disableGutters>
          <Typography
            variant="h4"
            noWrap
            component="div"
            sx={{ mr: 2, display: { xs: "none", md: "flex" } }}
          >
            <span style={{ color: color.primary }}>Dent</span>
            <span>Track</span>
          </Typography>

          <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
            <IconButton
              size="large"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: "block", md: "none" },
              }}
            >
              {pages.map((page) => (
                <MenuItem
                  key={page}
                  onClick={() => {
                    handleCloseNavMenu(page);
                  }}
                >
                  <Typography textAlign="center">{page}</Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>
          <Typography
            variant="h5"
            noWrap
            component="div"
            sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}
          >
            <span style={{ color: color.primary }}>Dent</span>
            <span>Track</span>
          </Typography>
          <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
            {pages.map((page) => (
              <Button
                key={page}
                onClick={() => {
                  handleCloseNavMenu(page);
                }}
                sx={buttonClasses.navBarButton}
              >
                {page}
              </Button>
            ))}
          </Box>

          <Box sx={{ flexGrow: 0 }}>
            <Button sx={buttonClasses.navBarButton} onClick={handleLogout}>
              Deconectare
            </Button>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
};
export default AppNavBar;
