import React from "react";
import {
  BrowserRouter,
  Navigate,
  Outlet,
  Route,
  Routes,
} from "react-router-dom";
import AccountPage from "../pages/Account/AccountPage";
import AccountsPage from "../pages/Accounts/AccountsPage";
import FinishedTreatmentPage from "../pages/FinishedTreatment/FinishedTreatmentPage";
import ForbiddenPage from "../pages/Forbidden/ForbiddenPage";
import LoginPage from "../pages/Login/LoginPage";
import NotFoundPage from "../pages/NotFound/NotFoundPage";
import TechniciansPage from "../pages/Technicians/TechniciansPage";
import TreatmentPage from "../pages/Treatment/TreatmentPage";
import YourActivityPage from "../pages/YourActivity/YourActivityPage";
import { Role } from "../types/Role";
import { NonProtectedRoutes, ProtectedRoutes } from "./Routes";
import { useUserContext } from "./UserContext";
import ReportsPage from "../pages/Reports/ReportsPage";

interface AppRouterProps {}
type ProtectedRouteProps = {
  requiredRoles?: Role[] | undefined;
};
const AppRouter: React.FC<AppRouterProps> = () => {
  let { user } = useUserContext();
  const ProtectedRoute = (props: ProtectedRouteProps) => {
    const hasRequiredRoles = () => {
      if (props.requiredRoles === undefined) return true;
      if (user?.role === Role.Admin) return true;
      if (props.requiredRoles.includes(user!.role)) return true;
      return false;
    };
    const isAuthenticated = () => {
      return user !== null;
    };

    return isAuthenticated() && hasRequiredRoles() ? (
      <Outlet />
    ) : isAuthenticated() && !hasRequiredRoles() ? (
      <Navigate to={NonProtectedRoutes.forbidden} />
    ) : (
      <Navigate to={NonProtectedRoutes.login} />
    );
  };
  return (
    <BrowserRouter>
      <Routes>
        <Route path="*" element={<NotFoundPage />} />
        <Route
          path={NonProtectedRoutes.forbidden}
          element={<ForbiddenPage />}
        />
        <Route path={NonProtectedRoutes.login} element={<LoginPage />} />
        <Route element={<ProtectedRoute />}>
          <Route
            path={ProtectedRoutes.yourActivity}
            element={<YourActivityPage />}
          />
          <Route
            path={ProtectedRoutes.technicians}
            element={<TechniciansPage />}
          />
          <Route path={ProtectedRoutes.accounts} element={<AccountsPage />} />
          <Route path={ProtectedRoutes.treatment} element={<TreatmentPage />} />
          <Route
            path={ProtectedRoutes.finishedTreatment}
            element={<FinishedTreatmentPage />}
          />
          <Route path={ProtectedRoutes.account} element={<AccountPage />} />
          <Route path={ProtectedRoutes.reports} element={<ReportsPage />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

export default AppRouter;
