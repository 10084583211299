import {
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { useSnackbar } from "notistack";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import AccountApi from "../../api/AccountApi";
import { AddAccountInput } from "../../api/models/input/AddAccountInput";
import { Account } from "../../api/models/output/GetAccountOutput";
import { Role, RoleNameValues } from "../../types/Role";
import { buttonClasses, textClasses } from "../styles";

interface AccountFormProps {
  account?: Account;
}

const AccountForm: React.FC<AccountFormProps> = ({ account }) => {
  const isCreate = account === undefined;
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const getInitialState = (): AddAccountInput => {
    if (isCreate || true) {
      return {
        firstName: "",
        lastName: "",
        email: "",
        phoneNumber: "",
        role: Role.Technician,
      };
    }
  };

  const [accountInput, setAccountInput] = useState<AddAccountInput>(
    getInitialState()
  );

  const handleInput = (e: React.FocusEvent<HTMLInputElement>) => {
    e.persist();
    setAccountInput({
      ...accountInput,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async () => {
    if (isCreate) {
      AccountApi.createAccount(accountInput)
        .then((response) => {
          enqueueSnackbar("Contul a fost adăugat cu succes", {
            variant: "success",
          });
          navigate(-1);
        })
        .catch((err) => {
          enqueueSnackbar("Contul nu s-a putut adăuga", {
            variant: "error",
          });
        });
    }
    // } else {
    //   TreatmentApi.editTreatment(treatmentInput, treatment!.id)
    //     .then((response) => {
    //       enqueueSnackbar("Tratamentul a fost editat cu succes", {
    //         variant: "success",
    //       });
    //       navigate(-1);
    //     })
    //     .catch((err) => {
    //       enqueueSnackbar("Tratamentul nu s-a putut edita", {
    //         variant: "error",
    //       });
    //     });
    // }
  };

  return (
    <Grid
      item
      container
      direction={"column"}
      spacing={3}
      justifyContent={"center"}
      alignItems={"stretch"}
      sx={{ minWidth: "600px" }}
    >
      <Grid item sx={textClasses.normal}>
        <h2>{"Adăugare cont"}</h2>
        <p>Completează câmpurile de mai jos pentru a{" adăuga un cont nou"}</p>
      </Grid>
      <Grid item sx={textClasses.normal}>
        <form
          onSubmit={(e: React.FormEvent<HTMLFormElement>) => {
            e.preventDefault();
            handleSubmit();
          }}
        >
          <Grid container direction="column" spacing={3}>
            <Grid item>
              <TextField
                id="lastName"
                name="lastName"
                type="text"
                placeholder="Nume"
                required
                label="Nume"
                onChange={handleInput}
                value={accountInput.lastName}
                fullWidth
              />
            </Grid>
            <Grid item>
              <TextField
                id="firstName"
                name="firstName"
                type="text"
                placeholder="Prenume"
                required
                label="Prenume"
                onChange={handleInput}
                value={accountInput.firstName}
                fullWidth
              />
            </Grid>

            <Grid item>
              <TextField
                id="phoneNumber"
                name="phoneNumber"
                type="tel"
                placeholder="Număr de telefon"
                required
                label="Număr de telefon"
                onChange={handleInput}
                value={accountInput.phoneNumber}
                fullWidth
              />
            </Grid>

            <Grid item>
              <TextField
                id="email"
                name="email"
                type="email"
                placeholder="Email"
                required
                label="Email"
                onChange={handleInput}
                value={accountInput.email}
                fullWidth
              />
            </Grid>
            <Grid item>
              {isCreate ? (
                <FormControl fullWidth required>
                  <InputLabel id="role">Rol</InputLabel>
                  <Select
                    labelId="role"
                    value={accountInput.role.toString()}
                    onChange={(event: any) => {
                      setAccountInput({
                        ...accountInput,
                        role: event.target.value,
                      });
                    }}
                    label="Rol"
                  >
                    {RoleNameValues.map((t) => (
                      <MenuItem key={t.roleName} value={t.value}>
                        {t.roleName}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              ) : null}
            </Grid>

            <Grid item container direction="row" spacing={1}>
              <Grid item xs={6}>
                <Button
                  sx={buttonClasses.secondary}
                  onClick={() => {
                    navigate(-1);
                  }}
                >
                  Anulează
                </Button>
              </Grid>
              <Grid item xs={6}>
                <Button sx={buttonClasses.primary} type="submit">
                  {isCreate ? "Adaugă" : "Salvează"}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </form>
      </Grid>
    </Grid>
  );
};

export default AccountForm;
