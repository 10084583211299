export const color = {
  text: {
    normal: "#441100",
    error: "#ff0011",
  },
  primary: "#40E0D0",
  primaryHover: "#30D0C0",
  secondary: "#BCBCBC",
  secondaryHover: "#d5d2d2",
  white: "#ffffff",
};
export const input = {
  minHeight: "48px",
  maxWidth: "360px",
};
export const text = {
  weight: {
    regular: 400,
    medium: 500,
    semiBold: 600,
    bold: 700,
    extraBold: 800,
  },
  size: {
    normal: "16px",
    medium: "20px",
    large: "32px",
    extraLarge: "48px",
    title: "64px",
  },
};
