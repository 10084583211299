import { Grid, TextField, Typography } from "@mui/material";
import React from "react";
import { textClasses } from "../styles";
import { getDateAndTime, parseDate } from "../../infrastructure/helperMethods";
import { getPatientTypeName } from "../../types/PatientType";
import { FinishedTreatment } from "../../api/models/output/GetFinishedTreatmentOutput";
interface FinishedTreatmentDetailsProps {
  finishedTreatment?: FinishedTreatment;
}

type DialogsState = {
  changeDialog: boolean;
  repeatStep: boolean;
  confirmStep: boolean;
  finishStep: boolean;
  deleteTreatment: boolean;
};

const FinishedTreatmentDetails: React.FC<FinishedTreatmentDetailsProps> = ({
  finishedTreatment,
}) => {
  return (
    <Grid
      item
      container
      direction={"column"}
      spacing={3}
      justifyContent={"center"}
      alignItems={"center"}
      xs={12}
      sx={{ minWidth: "750px" }}
    >
      <Grid
        item
        container
        direction={"row"}
        justifyContent={"center"}
        sx={textClasses.normal}
      >
        <Grid item container direction="column" xs={6}>
          <Grid item>
            <Typography component="span" sx={textClasses.label}>
              Număr identificare (ID):
            </Typography>
            {finishedTreatment?.treatmentId}
          </Grid>
          <Grid item>
            <Typography component="span" sx={textClasses.label}>
              Tratament:
            </Typography>
            {finishedTreatment?.treatmentName}
          </Grid>

          <Grid item>
            <Typography component="span" sx={textClasses.label}>
              Dată adăugare:
            </Typography>
            {getDateAndTime(parseDate(finishedTreatment?.startDate))}
          </Grid>
          <Grid item>
            <Typography component="span" sx={textClasses.label}>
              Dată finalizare:
            </Typography>
            {getDateAndTime(parseDate(finishedTreatment?.endDate))}
          </Grid>
          <br />

          <Grid item>
            <Typography component="span" sx={textClasses.label}>
              Medic:
            </Typography>
            {finishedTreatment?.medic}
          </Grid>
          <Grid item>
            <Typography component="span" sx={textClasses.label}>
              Tehnician:
            </Typography>
            {finishedTreatment?.technician}
          </Grid>
          <Grid item>
            <Typography component="span" sx={textClasses.label}>
              Pacient:
            </Typography>
            {finishedTreatment?.patientName}
          </Grid>
          <Grid item>
            <Typography component="span" sx={textClasses.label}>
              Tip Pacient:
            </Typography>
            {finishedTreatment?.patientType !== undefined
              ? getPatientTypeName(finishedTreatment!.patientType)
              : null}
          </Grid>
        </Grid>
        <Grid item container direction="column" xs={6}>
          <Grid item>
            <Typography sx={textClasses.label}>Descriere:</Typography>
            <TextField
              fullWidth
              sx={textClasses.area}
              inputProps={{ readOnly: Boolean(true) }}
              maxRows={6}
              multiline
              value={finishedTreatment?.description}
            />
          </Grid>
          <Grid item>
            <Typography sx={textClasses.label}>Istoric:</Typography>
            <TextField
              fullWidth
              sx={textClasses.area}
              inputProps={{ readOnly: Boolean(true) }}
              maxRows={6}
              multiline
              value={finishedTreatment?.log}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default FinishedTreatmentDetails;
