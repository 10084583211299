import axios, { AxiosRequestTransformer } from "axios";
import dayjs from "dayjs";
import qs from "qs";

const baseURL = process.env.REACT_APP_API_URL;

const axiosInstance = axios.create({
  baseURL: baseURL,
  withCredentials: true,
});
axiosInstance.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (
      401 === error.response.status &&
      !error.request.responseURL.includes("api/accounts/restore-session")
    ) {
      window.location.href = "/login";
    } else {
      return Promise.reject(error);
    }
  }
);

axiosInstance.interceptors.request.use(function (config) {
  config.headers!["Content-Type"] = "application/json";
  config.paramsSerializer = (params) =>
    qs.stringify(params, {
      serializeDate: (date: Date) => {
        return dayjs(date).format("YYYY-MM-DDTHH:mm:ssZ");
      },
    });
  return config;
});

export default axiosInstance;
