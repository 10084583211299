import { Button, Grid } from "@mui/material";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { buttonClasses } from "../../components/styles";
import AppNavBar from "../../infrastructure/AppNavBar";
import FinishedTreatmentDetails from "../../components/finishedTreatments/FinishedTreatmentDetails";
import FinishedTreatmentApi from "../../api/FinishedTreatmentApi";
import { FinishedTreatment } from "../../api/models/output/GetFinishedTreatmentOutput";

interface FinishedTreatmentPageProps {}

type TreatmentRenderParams = {
  treatmentId?: number;
};
const FinishedTreatmentPage: React.FC<FinishedTreatmentPageProps> = () => {
  const { state } = useLocation();
  const navigate = useNavigate();
  const renderParams = state as TreatmentRenderParams;
  const [finishedTreatment, setFinishedTreatment] = useState<
    FinishedTreatment | undefined
  >(undefined);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    getFinishedTreatment();
  }, [renderParams]);

  const getFinishedTreatment = () => {
    debugger;
    if (renderParams.treatmentId !== undefined) {
      FinishedTreatmentApi.getFinishedTreatment(renderParams.treatmentId)
        .then((response: any) => {
          setFinishedTreatment(response.data.data.finishedTreatment);
        })
        .catch(() => {
          enqueueSnackbar("Nu s-au putut obține date despre tratament", {
            variant: "error",
          });
        });
    }
  };

  return (
    <div>
      <AppNavBar />
      <Grid
        container
        direction={"column"}
        justifyContent={"center"}
        alignItems={"center"}
        spacing={3}
        sx={{ padding: "10px" }}
      >
        <Grid item>
          <FinishedTreatmentDetails finishedTreatment={finishedTreatment} />
        </Grid>

        <Grid item>
          <Button
            sx={buttonClasses.secondary}
            onClick={() => {
              navigate(-1);
            }}
          >
            Înapoi la pagina anterioară
          </Button>
        </Grid>
      </Grid>
    </div>
  );
};

export default FinishedTreatmentPage;
