import axiosInstance from "./config/axios";
import { AddEditTreatmentInput } from "./models/input/AddEditTreatmentInput";
class TreatmentApi {
  private controllerRoute = "/api/treatments";

  public getUserTreatments(userId: number) {
    return axiosInstance.get(this.controllerRoute + `?userId=${userId}`);
  }

  public getTreatment(treatmentId: number) {
    return axiosInstance.get(this.controllerRoute + `/${treatmentId}`);
  }

  public repeatStep(treatmentId: number, deadline: Date) {
    return axiosInstance.put(
      this.controllerRoute + `/${treatmentId}/repeat-step`,
      deadline
    );
  }

  public finishStep(treatmentId: number) {
    return axiosInstance.put(
      this.controllerRoute + `/${treatmentId}/finish-step`
    );
  }

  public confirmStep(treatmentId: number, deadline: Date) {
    return axiosInstance.put(
      this.controllerRoute + `/${treatmentId}/confirm-step`,
      deadline
    );
  }
  public changeDeadline(treatmentId: number, deadline: Date) {
    return axiosInstance.put(
      this.controllerRoute + `/${treatmentId}/change-deadline`,
      deadline
    );
  }

  public getBaseTreatments() {
    return axiosInstance.get(this.controllerRoute + `/base-treatments`);
  }

  public createTreatment(treatmentInput: AddEditTreatmentInput) {
    return axiosInstance.post(this.controllerRoute, treatmentInput);
  }

  public deleteTreatment(treatmentId: number) {
    return axiosInstance.delete(this.controllerRoute + `/${treatmentId}`);
  }

  public editTreatment(
    treatmentInput: AddEditTreatmentInput,
    treatmentId: number
  ) {
    return axiosInstance.put(
      this.controllerRoute + `/${treatmentId}`,
      treatmentInput
    );
  }
}
export default new TreatmentApi();
