import { color, text } from "../styleConstants";

export const buttonClasses = {
  primary: {
    width: "100%",
    borderRadius: "4px",
    border: "solid 1px",
    borderColor: color.primary,
    backgroundColor: color.primary,
    fontSize: text.size.normal,
    fontWeight: text.weight.medium,
    color: color.white,
    "&:hover": {
      backgroundColor: color.primaryHover,
      borderColor: color.primaryHover,
      cursor: "pointer",
    },
    "&:focus": {
      outline: "0px transparent ",
      cursor: "pointer",
    },
  },

  secondary: {
    width: "100%",
    borderRadius: "4px",
    border: "solid 1px",
    borderColor: color.secondary,
    backgroundColor: color.secondary,
    fontSize: text.size.normal,
    fontWeight: text.weight.medium,
    color: color.white,
    "&:hover": {
      backgroundColor: color.secondaryHover,
      borderColor: color.secondaryHover,
      cursor: "pointer",
    },
    "&:focus": {
      outline: "0px transparent ",
      cursor: "pointer",
    },
  },

  navBarButton: {
    my: 2,
    color: color.white,
    display: "block",
    borderRadius: "4px",
    border: "solid 1px",
    borderColor: color.secondary,
    backgroundColor: color.secondary,
    fontSize: text.size.normal,
    fontWeight: text.weight.medium,
    "&:hover": {
      backgroundColor: color.secondaryHover,
      borderColor: color.secondaryHover,
      cursor: "pointer",
    },
    "&:focus": {
      outline: "0px transparent ",
      cursor: "pointer",
    },
  },
  gridActionButton: {
    color: color.primary,
    borderRadius: "50px",
    border: "solid 2px",
    fontSize: text.size.normal,
    fontWeight: text.weight.medium,
    marginRight: "10px",
    "&:hover": {
      cursor: "pointer",
    },
    "&:focus": {
      outline: "0px transparent ",
      cursor: "pointer",
    },
  },

  actionButton: {
    color: color.primary,
    borderRadius: "4px",
    border: "solid 2px",
    fontSize: text.size.normal,
    fontWeight: text.weight.medium,
    width: "100%",
    "&:hover": {
      cursor: "pointer",
    },
    "&:focus": {
      outline: "0px transparent ",
      cursor: "pointer",
    },
  },
};

export const tableClasses = {
  table: {
    color: color.text.normal,
    fontWeight: text.size.normal,
  },
};

export const acordionClasses = {
  summary: {
    color: color.text.normal,
    fontSize: text.size.medium,
    fontWeight: text.weight.medium,
  },
};

export const textClasses = {
  error: {
    color: color.text.error,
    fontSize: text.size.normal,
    fontWeight: text.weight.regular,
  },
  normal: {
    color: color.text.normal,
    fontSize: text.size.normal,
    fontWeight: text.weight.regular,
  },
  label: {
    color: color.text.normal,
    fontSize: text.size.normal,
    fontWeight: text.weight.semiBold,
    marginRight: "2px",
  },
  area: {
    color: color.text.normal,
    fontSize: text.size.normal,
    fontWeight: text.weight.regular,
    borderColor: color.text.normal,
    width: "100%",

    "& .MuiInput-underline:after": {
      borderBottomColor: color.text.normal,
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: color.text.normal,
        borderWidth: "1px",
      },
      "&:hover fieldset": {
        borderColor: color.text.normal,
        borderWidth: "1px",
      },
      "&.Mui-focused fieldset": {
        borderColor: color.text.normal,
        borderWidth: "1px",
      },
    },
  },
};

export const dialogClasses = {
  title: {
    color: color.text.normal,
    fontSize: text.size.medium,
    fontWeight: text.weight.medium,
  },

  content: {
    marginTop: "10px",
    marginBottom: "20px",
    color: color.text.normal,
    fontSize: text.size.normal,
    fontWeight: text.weight.regular,
  },
};
