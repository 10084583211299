export const parseDate = (date: any): Date => {
  return new Date(date);
};

export const getDateAndTime = (date?: Date): string => {
  if (date === undefined) return "";
  const day = date.getDate();
  const month = date.getMonth() + 1;
  const year = date.getFullYear();
  const hours = date.getHours();
  const minutes = date.getMinutes();

  let result = "";
  result += day < 10 ? `0${day}/` : `${day}/`;
  result += month < 10 ? `0${month}/` : `${month}/`;
  result += `${year} `;
  result += hours < 10 ? `0${hours}:` : `${hours}:`;
  result += minutes < 10 ? `0${minutes}` : `${minutes}`;

  return result;
};

export const getCurrentDate = (delimiter: string) => {
  const d = new Date();
  return (
    ("0" + d.getDate()).slice(-2) +
    delimiter +
    ("0" + (d.getMonth() + 1)).slice(-2) +
    delimiter +
    d.getFullYear()
  );
};

export const getLastMonthDate = (): Date => {
  const date = new Date();
  date.setMonth(date.getMonth() - 1);

  return date;
};

export const DEFAULT_VALUE = "DEFAULT_VALUE";

export const getCurrentDateAndTime = (): Date => {
  var dateUtc = new Date();
  return new Date(dateUtc.getTime() - dateUtc.getTimezoneOffset() * 60000);
};

export const isEmpty = (data: any): boolean => {
  return data === undefined || data === null || data === "";
};
