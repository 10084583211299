import { TextField } from "@mui/material";
import React from "react";
import { getCurrentDateAndTime } from "../infrastructure/helperMethods";
import { textClasses } from "./styles";

interface DateTimePickerProps {
  value?: string;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  label?: string;
  allowPastDates?: boolean;
  hideTime?: boolean;
}

const DateTimePicker: React.FC<DateTimePickerProps> = ({
  value,
  onChange,
  label,
  allowPastDates,
  hideTime,
}: DateTimePickerProps) => {
  return (
    <TextField
      sx={textClasses.normal}
      type={hideTime ? "date" : "datetime-local"}
      defaultValue={
        value === undefined
          ? getCurrentDateAndTime()
              .toJSON()
              .slice(0, hideTime ? 10 : 16)
          : value
      }
      inputProps={
        allowPastDates
          ? undefined
          : {
              min: getCurrentDateAndTime()
                .toJSON()
                .slice(0, hideTime ? 10 : 16),
            }
      }
      onChange={onChange}
      fullWidth
      label={label}
    />
  );
};

export default DateTimePicker;
