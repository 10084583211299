import React, { useEffect, useState } from "react";
import { BaseTreatment } from "../../types/BaseTreatment";
import TreatmentApi from "../../api/TreatmentApi";
import { TreatmentsReportInput } from "../../api/models/input/TreatmentsReportInput";
import { useUserContext } from "../../infrastructure/UserContext";
import { Role } from "../../types/Role";
import {
  DEFAULT_VALUE,
  getLastMonthDate,
} from "../../infrastructure/helperMethods";
import {
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import { buttonClasses, textClasses } from "../../components/styles";
import { PatientTypeNameValues } from "../../types/PatientType";
import { useSnackbar } from "notistack";
import ReportsApi from "../../api/ReportsApi";
import AccountApi from "../../api/AccountApi";
import { Account } from "../../api/models/output/GetAccountsOutput";
import DateTimePicker from "../../components/DateTimePicker";
import AppNavBar from "../../infrastructure/AppNavBar";

interface ReportsPageProps {}

const ReportsPage: React.FC<ReportsPageProps> = () => {
  const { user } = useUserContext();
  const { enqueueSnackbar } = useSnackbar();
  const [reportInput, setReportInput] = useState<TreatmentsReportInput>({
    medicId: user?.role === Role.Medic ? user.id : null,
    technicianId: user?.role === Role.Technician ? user.id : null,
    baseTreatmentId: null,
    startDate: getLastMonthDate(),
    endDate: new Date(),
    patientType: null,
  });
  const [accounts, setAccounts] = useState<Account[]>();

  const [baseTreatments, setBaseTreatments] = useState<BaseTreatment[]>();

  useEffect(() => {
    TreatmentApi.getBaseTreatments()
      .then((response) => {
        setBaseTreatments(response.data);
      })
      .catch((err) => {
        enqueueSnackbar("Nu s-au putut obține tratamentele", {
          variant: "error",
        });
      });
    AccountApi.getAccounts()
      .then((response) => {
        setAccounts(response.data.accounts);
      })
      .catch((err) => {
        enqueueSnackbar("Nu s-au putut obține conturile", {
          variant: "error",
        });
      });
  }, []);

  const handleSubmit = async (date?: Date) => {
    ReportsApi.getTreatmentReports(reportInput)
      .then((x) =>
        enqueueSnackbar("Raportul a fost generat cu succes", {
          variant: "success",
        })
      )
      .catch((err) => {
        enqueueSnackbar("Raportul nu s-a putut genera", {
          variant: "error",
        });
      });
  };
  return (
    <div>
      <AppNavBar />
      <Grid
        container
        direction={"column"}
        justifyContent={"center"}
        alignItems={"center"}
        spacing={3}
        sx={{ padding: "10px" }}
      >
        <Grid item>
          <Grid
            item
            container
            direction={"column"}
            spacing={3}
            justifyContent={"center"}
            alignItems={"stretch"}
            sx={{ minWidth: "600px" }}
          >
            <Grid item sx={textClasses.normal}>
              <h2>{"Generare raport"}</h2>
              <p>Completează câmpurile de mai jos pentru a genera raportul</p>
            </Grid>
            <Grid item sx={textClasses.normal}>
              <form
                onSubmit={(e: React.FormEvent<HTMLFormElement>) => {
                  e.preventDefault();
                  handleSubmit();
                }}
              >
                <Grid container direction="column" spacing={3}>
                  <Grid item>
                    <FormControl fullWidth>
                      <DateTimePicker
                        onChange={(event) => {
                          setReportInput({
                            ...reportInput,
                            startDate: new Date(event.target.value),
                          });
                        }}
                        label="Data început"
                        value={reportInput.startDate.toJSON().slice(0, 10)}
                        allowPastDates={true}
                        hideTime={true}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item>
                    <FormControl fullWidth>
                      <DateTimePicker
                        onChange={(event) => {
                          setReportInput({
                            ...reportInput,
                            endDate: new Date(event.target.value),
                          });
                        }}
                        label="Data final"
                        value={reportInput.endDate.toJSON().slice(0, 10)}
                        allowPastDates={true}
                        hideTime={true}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item>
                    <FormControl fullWidth>
                      <InputLabel id="base-teratment">Tip Tratament</InputLabel>
                      <Select
                        labelId="base-treatment"
                        value={
                          reportInput.baseTreatmentId
                            ? reportInput.baseTreatmentId
                            : DEFAULT_VALUE
                        }
                        onChange={(event: any) => {
                          setReportInput({
                            ...reportInput,
                            baseTreatmentId:
                              event.target.value === DEFAULT_VALUE
                                ? null
                                : event.target.value,
                          });
                        }}
                        label="Tip Tratament"
                      >
                        <MenuItem
                          key={"Toate Tratamentele"}
                          value={DEFAULT_VALUE}
                        >
                          {"Toate Tratamentele"}
                        </MenuItem>
                        {baseTreatments?.map((t) => (
                          <MenuItem key={t.id} value={t.id}>
                            {t.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  {user?.role !== Role.Medic ? (
                    <Grid item>
                      <FormControl fullWidth>
                        <InputLabel id="medic">Medic</InputLabel>
                        <Select
                          labelId="medic"
                          value={
                            reportInput.medicId
                              ? reportInput.medicId
                              : DEFAULT_VALUE
                          }
                          onChange={(event: any) => {
                            setReportInput({
                              ...reportInput,
                              medicId:
                                event.target.value === DEFAULT_VALUE
                                  ? null
                                  : event.target.value,
                            });
                          }}
                          label="Medic"
                        >
                          <MenuItem key={"Toti Medicii"} value={DEFAULT_VALUE}>
                            {"Toți Medicii"}
                          </MenuItem>
                          {accounts
                            ?.filter(
                              (x) =>
                                x.role === Role.Medic ||
                                x.role === Role.MedicAdmin
                            )
                            .map((t) => (
                              <MenuItem key={t.id} value={t.id}>
                                {t.displayName}
                              </MenuItem>
                            ))}
                        </Select>
                      </FormControl>
                    </Grid>
                  ) : null}
                  {user?.role !== Role.Technician ? (
                    <Grid item>
                      <FormControl fullWidth>
                        <InputLabel id="technician">Tehnician</InputLabel>
                        <Select
                          labelId="technician"
                          value={
                            reportInput.technicianId
                              ? reportInput.technicianId
                              : DEFAULT_VALUE
                          }
                          onChange={(event: any) => {
                            setReportInput({
                              ...reportInput,
                              technicianId:
                                event.target.value === DEFAULT_VALUE
                                  ? null
                                  : event.target.value,
                            });
                          }}
                          label="Tehnician"
                        >
                          <MenuItem
                            key={"Toti Tehnicienii"}
                            value={DEFAULT_VALUE}
                          >
                            {"Toți Tehnicienii"}
                          </MenuItem>
                          {accounts
                            ?.filter((x) => x.role === Role.Technician)
                            .map((t) => (
                              <MenuItem key={t.id} value={t.id}>
                                {t.displayName}
                              </MenuItem>
                            ))}
                        </Select>
                      </FormControl>
                    </Grid>
                  ) : null}

                  <Grid item>
                    <FormControl fullWidth>
                      <InputLabel id="patientType">Tip Pacinet</InputLabel>
                      <Select
                        labelId="patientType"
                        value={
                          reportInput.patientType !== null
                            ? reportInput.patientType
                            : DEFAULT_VALUE
                        }
                        onChange={(event: any) => {
                          setReportInput({
                            ...reportInput,
                            patientType:
                              event.target.value === DEFAULT_VALUE
                                ? null
                                : event.target.value,
                          });
                        }}
                        label="Tip Pacient"
                      >
                        <MenuItem key={"Toate Tipurile"} value={DEFAULT_VALUE}>
                          {"Toate Tipurile"}
                        </MenuItem>
                        {PatientTypeNameValues.map((t) => (
                          <MenuItem key={t.patyentTypeName} value={t.value}>
                            {t.patyentTypeName}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>

                  <Grid
                    item
                    container
                    direction="row"
                    justifyContent={"center"}
                  >
                    <Grid item xs={6}>
                      <Button sx={buttonClasses.primary} type="submit">
                        {"Generează Raport"}
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </form>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default ReportsPage;
