import { Button, Grid } from "@mui/material";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import TreatmentApi from "../../api/TreatmentApi";
import { buttonClasses } from "../../components/styles";
import TreatmentDetails from "../../components/treatments/TreatmentDetails";
import TreatmentForm from "../../components/treatments/TreatmentForm";
import AppNavBar from "../../infrastructure/AppNavBar";
import { Account } from "../../api/models/output/GetTechniciansOutput";
import { Treatment } from "../../api/models/output/GetTreatmentOutput";

interface TreatmentPageProps {}

type TreatmentRenderParams = {
  isCreate?: boolean;
  isEdit?: boolean;
  treatmentId?: number;
  technician?: Account;
};
const TreatmentPage: React.FC<TreatmentPageProps> = () => {
  const { state } = useLocation();
  const navigate = useNavigate();
  const renderParams = state as TreatmentRenderParams;
  const [treatment, setTreatment] = useState<Treatment | undefined>(undefined);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    getTreatment();
  }, [renderParams]);

  const getTreatment = () => {
    if (renderParams.treatmentId !== undefined) {
      TreatmentApi.getTreatment(renderParams.treatmentId)
        .then((response) => {
          setTreatment(response.data.treatment);
        })
        .catch((err) => {
          enqueueSnackbar("Nu s-au putut obține date despre tratament", {
            variant: "error",
          });
        });
    }
  };

  return (
    <div>
      <AppNavBar />
      <Grid
        container
        direction={"column"}
        justifyContent={"center"}
        alignItems={"center"}
        spacing={3}
        sx={{ padding: "10px" }}
      >
        <Grid item>
          {renderParams.isCreate ? (
            <TreatmentForm technician={renderParams.technician} />
          ) : renderParams.isEdit ? (
            <TreatmentForm treatment={treatment} />
          ) : (
            <TreatmentDetails
              treatment={treatment}
              refreshData={getTreatment}
            />
          )}
        </Grid>
        {!(renderParams.isCreate || renderParams.isEdit) ? (
          <Grid item>
            <Button
              sx={buttonClasses.secondary}
              onClick={() => {
                navigate(-1);
              }}
            >
              Înapoi la pagina anterioară
            </Button>
          </Grid>
        ) : null}
      </Grid>
    </div>
  );
};

export default TreatmentPage;
