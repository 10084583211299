import { Button, Grid, Typography } from "@mui/material";
import React from "react";
import AccountsTable from "../../components/accounts/AccountsTable";
import AppNavBar from "../../infrastructure/AppNavBar";
import { acordionClasses, buttonClasses } from "../../components/styles";
import { ProtectedRoutes } from "../../infrastructure/Routes";
import { useNavigate } from "react-router-dom";
interface AccountsPageProps {}

const AccountsPage: React.FC<AccountsPageProps> = () => {
  const navigate = useNavigate();
  return (
    <div>
      <AppNavBar />
      <Grid
        container
        justifyContent={"flex-start"}
        alignItems={"stretch"}
        direction={"column"}
        sx={{ padding: "10px" }}
        spacing={1}
      >
        <Grid
          item
          container
          direction={"row"}
          alignItems={"stretch"}
          justifyContent={"space-between"}
        >
          <Grid item>
            <Typography sx={acordionClasses.summary}>Tabel Conturi</Typography>
          </Grid>
          <Grid item>
            <Button
              sx={buttonClasses.primary}
              type="button"
              onClick={() => {
                navigate(ProtectedRoutes.account, {
                  state: { isCreate: true },
                });
              }}
            >
              Adaugă cont
            </Button>
          </Grid>
        </Grid>
        <Grid item>
          <AccountsTable></AccountsTable>
        </Grid>
      </Grid>
    </div>
  );
};

export default AccountsPage;
